<template>
  <div class="app_con">
    <div class="actionsCon m-b-20">
      <el-button
        type="primary"
        size="small"
        icon="el-icon-plus"
        @click="showAdd"
        >新增</el-button
      >
    </div>
    <div class="table-wrap">
      <el-table
        :data="dataList"
        style="width: 100%; margin-bottom: 20px; text-align: center"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="分类名称"></el-table-column>
        <el-table-column prop="icon" label="图片" align="center">
          <template slot-scope="scope">
            <span>
              <img :src="'/api/' + scope.row.icon" class="tablePic" />
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="isShow" label="是否显示" align="center">
        </el-table-column>
        <el-table-column prop="id" label="id" align="center">
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              class="m-r-20"
              icon="el-icon-edit"
              :underline="false"
              @click="showEdit(scope.row)"
              >编辑</el-link
            >
            <el-link
              type="danger"
              icon="el-icon-delete"
              :underline="false"
              @click="handleDel(scope.row.id)"
              >删除</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="handleType == 'create' ? '新增' : '编辑'"
      :visible.sync="dialogShow"
      :close-on-click-modal="false"
      width="1000px"
      @close="dialogShow = false"
    >
      <el-form
        label-position="right"
        :rules="rules"
        ref="form"
        label-width="80px"
        :model="form"
      >
        <el-row :gutter="20">
          <el-col :span="8"
            ><el-form-item label="名称" prop="name"
              ><el-input
                v-model="form.name"
                size="small"
              ></el-input></el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="层级" prop="pid">
              <el-cascader
                v-model="form.pid"
                :options="dataList"
                :props="optionProps"
                size="small"
                label="name"
              ></el-cascader> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="是否显示">
              <el-switch
                v-model="form.isShow"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="排序" prop="sorts">
              <el-input
                v-model="form.sorts"
                size="small"
                placeholder="越小越靠前"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="16"
            ><el-form-item label="图片">
              <el-upload
                action="/api/file/upload"
                list-type="picture-card"
                :on-success="handlePictureSuccess"
                :on-remove="handleRemove"
                :file-list="form.fileList"
                :limit="1"
                :on-exceed="handlePictureExceed"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item></el-col
          >
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";
export default {
  data() {
    // let self = this;
    return {
      dialogShow: false,
      dataList: [],
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        emitPath: false,
      },
      handleType: "create",
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0,
        apiName: "/classificationGoods",
      },
      form: {
        name: "",
        pid: "",
        description: "",
        isShow: 0,
        icon: "",
        sorts:0
      },
      fileList: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    this.getDataList();
  },
  watch: {},
  mounted() {},

  filters: {},

  methods: {
    handleDel(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let ids = [];
          ids.push(id);
          let res = await api.commonPost({
            apiName: "/classificationGoods/remove",
            ids: ids,
          });
          if (res.code == 200) {
            this.$message.success(res.message);
            this.getDataList();
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    showEdit(obj) {
      this.handleType = "update";
      this.form = obj;
      this.form.fileList = [];
      this.form.fileList.push({ name: "icon", url: "/api/" + this.form.icon });
      this.dialogShow = true;
    },
    handlePictureExceed() {
      this.$message.error("超过上传数量！");
    },
    handleRemove(file) {
      if (file.status == "success") {
        this.form.icon = "";
      }
    },
    handlePictureSuccess(response) {
      this.form.icon = response.data.path;
    },
    handleSave() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let obj = Object.assign(
            {},
            { apiName: "/classificationGoods/" + this.handleType },
            this.form
          );

          let res = await api.commonPost(obj);
          if (res.code == 200) {
            this.$message.success(res.message);
            this.getDataList();
            this.dialogShow = false;
          }
        } else {
          return false;
        }
      });
      // console.log(this.form);
    },
    handleUploadChange() {
      console.log(this.fileList);
    },
    showAdd() {
      this.form = {};
      this.form.fileList = [];
      this.handleType = "create";
      this.dialogShow = true;
    },
    async getDataList() {
      try {
        let res = await api.commonGet(this.pageInfo);
        this.dataList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tablePic {
  width: 60px;
  height: 60px;
  border-radius: 4px;
}
/deep/ .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
